import '../../assets/css/footer.css'
import Logo  from "../../assets/img/logo.png"
import { NavLink } from 'react-router-dom'


const Footer = (props) => {

    return (
        <footer className="footer">
            <div className="first-footer-sect">
                <div className="foot-sect-1">
                    <div className="foot-logo-img-container"> <img className="foot-logo-img" src={Logo} alt=""/></div>
                    <h3>Connect With Us</h3>
                     <span className='footer-icons'>
                        <span><a href="https://www.instagram.com/brees_little_stars/" rel="noreferrer" target="_blank"><i className="fab fa-instagram"></i></a></span>
                        <span><a href="http://" rel="noreferrer" target="_blank"><i className="fab fa-facebook-square"></i></a></span>
                    </span>
                </div>


                <div className="foot-sect-2">
                  <h3>Contact Us</h3>

                    <ul className="fa-ul">
                        <li><span className="fa-li"><i className="fas fa-map-marker-alt"></i></span>Greenwich, SE10.</li>
                        <li><span className="fa-li"><i className="fas fa-mobile-alt"></i></span><a href="tel:+07961 713476">07961 713476</a></li>
                        <li><span className="fa-li"><i className="fa fa-globe" aria-hidden="true"></i></span><a href="mailto:info@breeslittlestars.co.uk">info@breeslittlestars.co.uk</a></li>
                    </ul>
                </div>


                <div className="foot-sect-3">
                
                <ul>
                    <li><h3>Pages</h3></li>
                    <li><a href="http://https://www.breeslittlestars.co.uk/">Home</a></li>
                    <li><NavLink to="/about">About Us</NavLink></li>
                    <li><NavLink to="/services">Services</NavLink></li>
                    <li><a href="http://https://www.breeslittlestars.co.uk/gallery">Gallery</a></li>
                    <li><NavLink to="/calendar">Calendar</NavLink></li>
                    <li><NavLink to="/career">Careers</NavLink></li>
                </ul>
            </div>
            </div>

            <div className="second-footer-sect">
                <p>©{new Date().getFullYear()} Brees Little Stars. All rights reserved.</p>
            </div>
        </footer>
    );
}


export default Footer