import { useState, useEffect, useLayoutEffect } from "react"
import { useHistory } from "react-router-dom"
import "../assets/css/gallery.css"
import Header from "./attactments/header"
import MinHeader from "./attactments/min-header";
import Footer from "./attactments/footer"
import RegisterationModal from "./attactments/registerModal";
import { data } from "../services/data"
import ScrollLink from "./attactments/scrollLink";



const Gallery = () => {
    const history = useHistory();

    const [galleryStore, setGalleryStore] = useState('none');
    const [scroll, stopScroll] = useState(false);

    
    //const loadScroll = (expression) => expression;  
    useLayoutEffect(() => {
        if(scroll === true){
            return;
        }else {
            window.scrollTo(0, 0)
        }
    })


    const [img, setImg] = useState([])
    
    useEffect(() => {
        const sortedArray = data.imgData.sort((a,b) => {
            let fa = a.title;
            let fb = b.title;

            if (fa > fb) {
                return -1;
            }
            if (fa < fb) {
                return 1;
            }
            return 0;
        }).map(a => a);

        if(img[0]=== undefined) setImg(sortedArray)
    }, [img])
    

    const imageFilterFunc = e => {
        let category = e.target.className;
        let arr = [];
        if(category === 'all'){
        data.imgData.sort((a,b) => {
            let fa = a.title;
            let fb = b.title;

            if (fa > fb) {
                return -1;
            }
            if (fa < fb) {
                return 1;
            }
            return 0;
        }).forEach(item => {
                    setImg('')
                    arr.push(item);   
            });
            setImg(arr)
        }else{
            data.imgData.sort((a,b) => {
            let fa = a.title;
            let fb = b.title;

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        }).forEach(item => {
                if(item.category === category) {
                    setImg('')
                    arr.push(item)
                }     
            });
            setImg(arr)
        }
        
    }
    

    const viewRedirect = (id, arr) => {

        history.push({
            pathname: '/view',
            state: {
                id: id,
                imgData: arr
            }
        })
    }
  
    return (
        <>
            <Header setHome={setGalleryStore} />
            <MinHeader Home={galleryStore} />
            
            <section className="gal-sect-1">
                <div className="gal-sect-1-img-container">
                    <div className="gal-img-1"></div>
                    <div className="gal-img-2 gal-img"></div>
                    <div className="gal-img-3 gal-img"></div>
                    <div className="gal-img-4 gal-img"></div>
                    <div className="gal-img-5 gal-img"></div>
                    <div className="gal-img-6 gal-img"></div>
                    <div className="gal-img-7 gal-img"></div>
                </div>
            </section>

            <section className="gal-sect-2">
                <div className="gal-sect-2-nav">
                    <nav className="gal-nav">
                            <span className="all" id="all" onClick={(e)=>{
                                imageFilterFunc(e)
                                stopScroll(true)
                                }}>All</span>
                            <span className="nursery-and-pre-school" id="pre-school" onClick={(e)=>{
                                stopScroll(true)
                                imageFilterFunc(e)
                                }}>Nusery / Pre-school</span>
                            <span className="after-school-club" id="after-school-club" onClick={(e)=>{
                                stopScroll(true)
                                imageFilterFunc(e)
                                }}>After School Club</span>
                            <span className="holiday-club" id="holiday-club" onClick={(e)=>{
                                stopScroll(true)
                                imageFilterFunc(e)
                            }}>Holiday Club</span>
                    </nav>
                </div>

                <div className="gal-sect-2-arr-of-img">
                    {img.map((item, id) => {
                        
                        
                            return(
                                <div 
                                  className="img-gal-view-img-1 gal-img" 
                                  key={id}
                                  style={{
                                            backgroundImage: `url(${item.path})`,
                                        }}
                                  >
                                    <div 
                                        className="gal-view-container">
                                        <span 
                                            className="gal-view"
                                            id={item.title}
                                            onClick={(e)=>{
                                            viewRedirect(id, img)
                                            console.log(item)
                                        }}>View</span>
                                    </div>
                                </div>
                            )
                    })}
                </div>
            </section>
            <RegisterationModal/>
            <ScrollLink/>
            <Footer stopScroll={stopScroll}/>
        </>
    );
}


export default Gallery;