import { useState, useRef, useEffect } from "react"

const CountdownTimer = () => {
  const [ countDownDate, setCountDownDate ] = useState("");
  const [days, setDays] = useState("00");
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");

    let interval = useRef();

    const starterTimer = () => {
      let countDownDate = new Date('July 29, 2024 00:08:00').getTime();
       let dateNow = new Date().getTime();
       let newEvent = new Date('July 29, 2024 00:08:00').getTime();
      // let b = new Date('March 30, 2022 00:08:00').getTime();

      setCountDownDate(dateNow === newEvent?dateNow: newEvent)


      interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = countDownDate - now;

        const days = Math.floor(distance / (1000 * 60 * 60 *24));
        const hours = Math.floor((distance % (1000 * 60 * 60 *24) / (1000 * 60* 60)));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        
        if(distance < 0) {
          clearInterval(interval.current)
        }else {
            setDays(days)
            setHours(hours)
            setMinutes(minutes)
            setSeconds(seconds)
        }
      }, 1000);
    }
    
    useEffect(()=>{
        starterTimer();
        return()=> {

        }
    });


  return (
      <div className="countdown-time">
        <div className="count-days">
          <h3>{days}</h3>
          <p>Days</p>
        </div>
          <span 
            className="colon">
            :
          </span>
        <div className="count-hours">
          <h3>{hours}</h3>
          <p>Hours</p>
        </div>
        <span 
            className="colon">
            :
          </span>
        <div className="count-minutes">
          <h3>{minutes}</h3>
          <p>Minutes</p>
        </div>
        <span 
            className="colon">
            :
          </span>
        <div className="count-seconds">
          <h3>{seconds}</h3>
          <p>Seconds</p>
        </div>
      </div>
  )
}



export default CountdownTimer