import { NavLink } from "react-router-dom"
import '../../assets/css/header.css'

const MinHeader = (props) => {

    return(
            <nav 
            style={{
                display: props.Home
            }}
            className="min-header-nav">
                    <ul>
                    <li><NavLink  
                            className="header-button"
                            to="/register"
                            > Register Your Child</NavLink></li>
                        <li><NavLink to="/">HOME</NavLink></li>
                        <li><NavLink to="/about">ABOUT US</NavLink></li>
                        <li><NavLink to="/services">SERVICES</NavLink></li>
                        <li><NavLink to="/calendar">CALENDAR</NavLink></li>
                        <li><NavLink to="/gallery">GALLERY</NavLink></li>
                        <li><NavLink to="/contact">CONTACT</NavLink></li>
                        <li><NavLink to="/career">CAREER</NavLink></li>
                    </ul>
            </nav> 
    )
}



export default MinHeader