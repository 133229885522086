import { useState, useLayoutEffect } from "react"
import "../assets/css/services-extended.css"
import Header from "./attactments/header";
import MinHeader from "./attactments/min-header";
import Footer from "./attactments/footer";
import CarouselContainer from "./attactments/quote-carousel";
import litleStars from "../assets/img/little-stars.png"
import RegisterationModal from "./attactments/registerModal";
import ScrollLink from "./attactments/scrollLink";



const ServicesExtended1 = () => {

    const [Home, setHome] = useState('none');

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return (
        <div className="services-1">
            <Header setHome={setHome} />
            <MinHeader Home={Home} />
                <section className="serv-extend-container">
                <div className="showcase-text">
                    <h2>Little Stars</h2>
                    <h1>Age: 1 to 2 years</h1>
                </div>
                </section>

                <section className="serv-extend-sect-2">
                    <div className="serv-sect-2-container-1">
                            <div>
                                <p>
                                     As baby stars become increasingly mobile and have gained physical independence and control, they will be transitioned gradually into little stars, helping them become independent in daily activities. They will also have the opportunity to transition to rising stars as they approach 36 months. Our little stars enjoy an enriched timetable dedicated to ensuring they are developing in all areas of learning. Our dedicated staff ensure that their next steps are achieved through stimulating activities. Beyond learning, we strive to help them become confident in eating, relating with their peers, accessing physical activities, and the outside world. They are treated to regular visits in and around our local area to help them learn about the world around them.
                                </p>

                            </div>
                            <div className="serv-sect-2-img-container services-extend-sect-1-img-case">
                                <img src={litleStars} alt="litleStars" />
                            </div>
                        </div>
                </section>
                
                <CarouselContainer/>
                <RegisterationModal/>
                <ScrollLink/>
            <Footer/>
        </div>
    );
}

export default ServicesExtended1;