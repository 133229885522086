import React from "react"
import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Carousel } from "react-bootstrap"
import "../assets/css/gallery-extended.css"
import Header from "./attactments/header";
import MinHeader from "./attactments/min-header";
import Footer from "./attactments/footer";
import ScrollLink from "./attactments/scrollLink";



const GalleryExtended = (props) => {
    
  const [store, setStore] = useState({
      propsId: null,
      imgData: []
  })
   const history = useHistory();



    useEffect(() => {
        if(props.location.state === undefined) {
            history.push('/')
            return;
        }else {
            const { id, imgData } = props.location.state
            setStore({propsId: id, imgData: imgData})
        }
    },[props.location.state, history]);


   

    
    const [Home, setHome] = useState('none');

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    let arr = [...store.imgData]
    const filteredArrWithItem = arr.filter((item, id) => id >= store.propsId);
    const filteredArrBeforeItem = arr.filter((item, id) => id < store.propsId);
    filteredArrWithItem.push(...filteredArrBeforeItem)
  


    return (
            <div className="galleryContainer">
                <Header setHome={setHome} />
                <MinHeader Home={Home} />
                <section className="gal-ext-sect-1">
                    <h1>Pictures are for memories</h1>

                    <Carousel activeIndex={index} onSelect={handleSelect} indicators={true} interval={10000}>
                        {
                            filteredArrWithItem.map((item, id) => {
                                return (
                                    <Carousel.Item key={id}>
                                        <div
                                            className="gal-slide-img"
                                            style={{
                                                backgroundImage: `url(${item.path})`,
                                            }}
                                        >
                                        </div>
                                    </Carousel.Item>
                                );
                            })
                        }

                    </Carousel>
                </section>
                <Footer />
                <ScrollLink/>
            </div> 
    );
}

export default GalleryExtended