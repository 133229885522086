import { useState, useLayoutEffect } from "react"
import "../assets/css/contact.css"
import Header from "./attactments/header"
import MinHeader from "./attactments/min-header";
import Footer from "./attactments/footer"
import ContactForm from "./attactments/contact-form";
import RegisterationModal from "./attactments/registerModal";
import ScrollLink from "./attactments/scrollLink"



const Contact = () => {
    const [contactStore, setContactStore] = useState('none');

    useLayoutEffect(() => {
    window.scrollTo(0, 0)
    });
    return (
        <>
            <Header setHome={setContactStore} />
            <MinHeader Home={contactStore}/>

            <section className="contact-showcase">
                <div className="contact-showcase-frame">
                    <div className="contact-showcase-text-input">
                        <h1>Contact Us</h1>
                    </div>
                </div>
            </section>

            <section className="contact-sect-2">


                <div className="cont-sect-2-carton">
                    <h1>How can we help you?</h1>
                    <div className="con-sect-2-container">
                        <div className="cont-form-container">
                        {/*'https://bls-serve.herokuapp.com/user/message'*/}
                            <ContactForm url={'http://localhost:5500/user/message'}/>
                        </div>

                        <div className="con-sect-address">
                            <div>
                                <h1>Address</h1>

                                <p><i className="fas fa-map-marker-alt"></i> Greenwich, SE10</p>
                                <p><i className="far fa-envelope"></i> <a className="address-link" href="mailto:info@breeslittlestars.co.uk">info@breeslittlestars.co.uk</a> </p>
                                <p><i className="fas fa-mobile-alt"></i> <a className="address-link" href="tel:+07961 713476">07961 713476</a></p>
                                <p><i className="fa fa-globe" aria-hidden="true"></i> <a className="address-link" href="http://www.breeslittlestars.co.uk">www.breeslittlestars.co.uk</a></p>
                            </div>
                            <div className="mapouter">
                                <div className="gmap_canvas">
                                    <iframe title="map" className="map-frame" id="gmap_canvas" src="https://maps.google.com/maps?q=Greenwich%20High%20Road,%20London,%20SE10%208JA&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} />
                                    <a href="https://www.whatismyip-address.com" style={{display:'none'}}>map</a>
                                    <br />
                                    <a href="https://www.embedgooglemap.net" style={{display:'none'}}>map</a>
                                    {/* <style dangerouslySetInnerHTML={{ __html: ".gmap_canvas {overflow:hidden;background:none!important;height:500px;width:600px;}" }} />*/}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <RegisterationModal />
            <ScrollLink/>
            <Footer />
        </>
    );
}


export default Contact;