import { useState, useLayoutEffect } from "react"
import '../assets/css/about.css'
import { Link } from "react-router-dom"
import Footer from "./attactments/footer"
import Header from "./attactments/header"
import ofsted from "../assets/img/association/Ofsted-logo-gov.uk_.png";
import ndna from "../assets/img/association/ndna.jpg";
import greenwich from "../assets/img/association/royal_brough_of_greenwich.jpg";
import babysDay from "../assets/img/association/babys_day.png";
import MinHeader from "./attactments/min-header";
import kidsPainting from "../assets/img/kids_painting.png"
import teacherTeaching from "../assets/img/teacher-teaching-3435653234.jpg"
import childLearning from "../assets/img/child-learning-brees-little-stars-well-planned-curricullum.png"
import RegisterationModal from "./attactments/registerModal";
import ScrollLink from "./attactments/scrollLink"


const About = () => {
    const [aboutStore, setAboutStore] = useState('none');


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    },[]);

    return (
        <>
            <Header setHome={setAboutStore} />
            <MinHeader Home={aboutStore} />

            <section className="about-showcase">
                <div className="about-showcase-frame">
                    <div className="about-showcase-text-input">
                        <h1>
                            About Bree’s Little Stars
                        </h1>
                        <p>
                            “We believe in and value the uniqueness of each child we serve, and we provide an uncompromised quality service.”
                        </p>
                    </div>
                </div>
            </section>


            <section className="about-sect-2">
                <h1>Who we are</h1>
                <div className="about-sect-2-child-div">
                    <div className="about-sect-2-frame1">
                        <p>
                            Bree’s Little Stars had a very humble beginning in the comfort of Bree’s own home. It was founded in 2012 after being Ofsted approved, and began by offering childminding services in Greenwich. The driving force behind Bree’s Little Stars has been and remains the love and care of children. Our aim is to make a difference in every child’s life, ensuring a strong foundation through good quality learning and development. We believe that childhood is paramount to a child’s future, and we want to ensure they will remember Bree’s Little Stars as their happy place, a home away from home. We absolutely love what we do and we have every intention of continuing to make a difference, one child at a time.
                    </p>
                        <Link
                            to="/register"
                            className="global-button"
                        > Register Your Child </Link>
                    </div>

                    <div className="who-we-are-text-container">
                                <img className="sect2img" src={kidsPainting} alt="kids_ainting" />
                    </div>
                </div>
            </section>


            <section className="about-sect-3">
                <h1>Our Mission</h1>

                <div className="sect-3-card">
                    <div className="sect-3-card-1">
                        <p>
                            At Bree’s Little Stars, we believe that every child has great potential and we support each child so that they can achieve their potential. We believe that every child deserves the best possible start in life, and as such we support each child so that they can achieve their potential. We believe in and value the uniqueness of each child we serve, and we provide an uncompromised quality service for them.
                        </p>
                    </div>
                    <div className="sect-3-card-2">
                        <p>
                            We do this by encouraging good relationships with parents and carers to build a mutual understanding, and provide consistency in the care of each child. We aim to provide developmentally appropriate, fun, and educational activities for all children that promotes a sense of belonging.
                        </p>
                    </div>
                    <div className="sect-3-card-3">
                        <p>
                            Parents can be assured that their children are being handled with great care in a homely environment and with professionalism. We regularly update parents on their child’s progress and development to foster good parent partnership.
                        </p>
                    </div>
                    <div className="sect-3-card-4">
                        <p>
                            As caregivers and educators, our mission is to love, nurture, and educate each child and to provide a safe and secure space for them. We have a uniquely stimulating environment and this fosters children’s natural desire to explore, discover, create, and be secure in themselves.
                        </p>
                    </div>
                </div>
            </section>


            <section className="sect-3 about-sect-3-color">
                <h2>Our Team</h2>
                <p className="sect-3-list">
                    We look for practitioners who have genuine interest in caring for children.
                </p>


                <div className="sect-3-grid about-sect-3-grid">
                    <div>
                        <div className="sect-3-img about-sect-3-img-reflect">
                            <div className="img">
                                <img src={teacherTeaching} alt="teacherTeaching" />
                            </div>
                        </div>
                    </div>

                    <div className="sect-3-frame2">
                        <ul className="fa-ul">
                            <li><span className="fa-li"><i className="fas fa-star star-one"></i></span>
                            Bree’s Little Stars is committed to ensuring all staff members looking after children are suitable to fulfil the requirements of their role. An effective system has been put in place to ensure that practitioners and any other person who is likely to have regular contact with children are suitable.
                        </li>
                            <li><span className="fa-li"><i className="fas fa-star star-two"></i></span>
                            Early years practitioners have been chosen for their skills, knowledge and experience within the early childhood field. They are suitably qualified for the position that they undertake and are approved by the appropriate Government Departments.
                        </li>
                            <li><span className="fa-li"><i className="fas fa-star star-two"></i></span>
                                Practitioners selected must be committed to providing quality care and education and show a true commitment to the children and families within the Bree’s Little Stars family. We fully support our staff through their ongoing learning and training to enhance their professionalism.
                        </li>
                        </ul>
                    </div>
                </div>

                <div className="sect-3-frame-3">
                    <Link
                         to="/register"
                        className="header-button"
                    > Register Your Child </Link>
                </div>
            </section>


            <section className="about-sect-2 about-sect-4">
                <h1>Well Planned Curriculum</h1>
                <div className="about-sect-2-child-div">
                    <div className="about-sect-2-frame1">
                        <p>
                            The first five years of a child’s life are a time of rapid physical and intellectual growth. We recognise the vital importance of this period and the formative nature of learning. As a result, we make a constant effort to ensure that every experience offered to your child is of the highest possible quality.
                        </p>

                        <p>
                            We have created an engaging and inclusive curriculum focused on developing children to become independent learners with the ability to promote wider thinking across all the areas of the curriculum. It is our intention that children are immersed in an environment that simulates them to become curious, imaginative, and explorative in the seven areas of learning in the EYFS. This is achievable through a wide variety of topics, themes, and activities carefully created and supported by our educators. We aim to promote understanding of fundamental British values and give children the cultural capital they need to acquire a better understanding of the wider community and the world around them. We treat each child as an individual and respect their beliefs and cultures, encouraging inclusivity and diversity.
                        </p>

                        <p>Development matters in the Early Years Foundation Stage (EYFS)</p>
                        <a href="https://foundationyears.org.uk/files/2012/03/Development-Matters-FINAL-PRINT-AMENDED.pdf" className="global-button" target="blank"> Download Here </a>
                    </div>

                    <div className="about-sect-2-frame1-div">
                        <img src={childLearning} alt="teacher_and_kid" />
                    </div>
                </div>
            </section>


            <section className="about-sect-5 p-5">


                <div className="about-sect-5-list-container">

                        {/*
                        
                            <div>
                                <p>The three prime areas are:</p>
                                <ul className="fa-ul">
                                    <li><span className="fa-li"><i className="fas fa-star star-about"></i></span>
                                        Communication and Language
                                </li>
                                    <li><span className="fa-li"><i className="fas fa-star star-about"></i></span>
                                        Physical Development
                                </li>
                                    <li><span className="fa-li"><i className="fas fa-star star-about"></i></span>
                                        Personal, Social and Emotional Development
                                </li>
                                </ul>
                            </div>

                                       <div>
                        <p>The additional and specific areas to enhance the prime areas are:</p>
                        <ul className="fa-ul">
                            <li><span className="fa-li"><i className="fas fa-star star-about"></i></span>
                                Literacy
                        </li>
                            <li><span className="fa-li"><i className="fas fa-star star-about"></i></span>
                                Mathematics
                        </li>
                            <li><span className="fa-li"><i className="fas fa-star star-about"></i></span>
                                Understanding the World
                        </li>
                            <li className="last-li"><span className="fa-li"><i className="fas fa-star star-about"></i></span>
                                Expressive Arts and Design
                        </li>
                        </ul>
                    </div>

                    <div>
                        <ul className="fa-ul last-li-extend">
                            <li><span className="fa-li"><i className="fas fa-star star-about"></i></span>
                            Expressive Arts and Design
                        </li>
                        </ul>
                    </div>
                        */}

                </div>

            </section>

            <section className="association-container">
                <div className="associate-header">
                    <h1>We’re proudly associated with…</h1>
                </div>
                <div className="associate-image">
                    <img src={ofsted} alt="ofsted" />
                    <img src={ndna} alt="ndna" />
                    <img src={greenwich} alt="greenwich" />
                    <img src={babysDay} alt="baby's day" />
                </div>
            </section>
            <RegisterationModal />
            <ScrollLink />
            <Footer />
        </>
    );
}



export default About;