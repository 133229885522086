import { useState, useLayoutEffect } from "react"
import "../assets/css/career.css"
import  sewingBoy from "../assets/img/career-sewing-boy.png"
import Header from "./attactments/header"
import MinHeader from "./attactments/min-header";
import Footer from "./attactments/footer"
import ContactForm from "./attactments/contact-form";
import RegisterationModal from "./attactments/registerModal";
import ScrollLink from "./attactments/scrollLink"

const Career = () => {
    const [careerStore, setCareerStore] = useState('none');

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Header setHome={setCareerStore} />
            <MinHeader Home={careerStore} />
            <section className="career-sect-1">
                <h1>Careers</h1>
            </section>


            <section className="car-sect-1">
                <div className="serv-sect-2-container-1">
                    <div>
                        <h1>Careers</h1>
                        <p>
                            We are always on the lookout for passionate, committed and reliable childcare professionals to join our growing team. We have various positions in our company and would love to give you the opportunity to be valued in the workplace. If you are looking for a change and want to work in an environment that challenges you, please email your CV along with a covering letter and the type of position you are looking for to <a href="mailto:hr@breeslittlestars.co.uk">hr@breeslittlestars.co.uk</a>
                        </p>
                    </div>
                    <div className="serv-sect-2-img-container car-sect-img-container">
                        <img src={sewingBoy} alt="chef_girl" />
                    </div>
                </div>
            </section>


            <section className="car-sect-3">
                <h1>Have what it takes?</h1>
                <p>Feel free to send us a message.</p>
                <div className="cont-form-container car-sect-4-align-center">
                    <div className="car-sect-4-form-carton car-sect-3-form-container">
                        <ContactForm url={'http://localhost:5500/user/career'}/>
                    </div>
                </div>
            </section>
            <RegisterationModal/>
            <ScrollLink/>
            <Footer />
        </>
    );
}


export default Career;