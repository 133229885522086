

const AlertContainer = (props) => {
  //const [value, onChange] = useState(new Date());



  return (
    <div>
                            
        <div className="bree-success-alert"
            style={{
                display: props.display,
                background: props.backgroundColor
            }}
        >
        <p style={{color:'#ffffff', marginBottom:0, fontSize:'16px'}}>{!props.alert?"":props.alert}</p>
        <span
                style={{
                    position:'absolute',
                    right:'20px',
                    color:'#ffffff',
                    cursor: 'pointer' 
                }}
                onClick={()=>{
                    document.querySelector('.bree-success-alert').style.display = 'none';
                }}>x</span>
        </div>
                            
    </div>
  );
}


export default AlertContainer