import React,{ useState } from "react"
import "../../assets/css/contactForm.css"
import axios from "axios"
import AlertContainer from "./alert"



const ContactForm = (props) => {
    const [alert, setAlert] = useState("");
    const [color, setColor] = useState("");

        console.log(props.url)
    const handleSubmit = (e) => {
        e.preventDefault();
        const [name, email, message] = e.target.elements
        let user = { name: name.value, email: email.value, message: message.value }
        
        if (nameValidator() && emailValidator() && messageValidator()) {
                         axios.post(props.url, user)
                           .then((data) => {
                                setAlert(data.data);
                                setColor('green');
                                setTimeout(() => setAlert(""), 8000);
                            return true;
                           }).catch((err) => {                 
                            const arrErr = Object.entries(err)[2];
                            if(arrErr[1] === true || arrErr[1] === undefined) {
                                setAlert('Network Error');
                                setColor('rgb(241, 37, 37)');
                                setTimeout(() => setAlert(""), 8000);
                            }
                            if (err.response) {
                                setAlert(err);
                                setColor('rgb(241, 37, 37)');
                                setTimeout(() => setAlert(""), 8000);
                            }
                    })
        }



        function nameValidator() {
            if (inputTextisEmpty(name)) return;
            return true;
        }

        function emailValidator() {
            if (inputTextisEmpty(email)) return;
            return true;
        }

        function messageValidator() {
            if (inputTextisEmpty(message)) return;
            return true;
        }

        function inputTextisEmpty(element) {
            if (empty(element.value.trim())) {
                inValid(element);
                return true;
            }
            else {
                valid(element);
                return false;
            };
        }


        function inValid(element) {
            element.nextElementSibling.style.visibility = 'visible';
        }

        function valid(element) {
            element.nextElementSibling.style.visibility = 'hidden';
        }

        function empty(value) {
            if (value === '') return true;
            return false;
        }

        
    }



    return (
        <React.StrictMode>
        <form className="contacts-form" onSubmit={handleSubmit}>
            <div className="contacts-row">
                <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                />
                <div className="error name-error"><span><i className="fas fa-exclamation-triangle"></i> Fill in your name</span></div>
            </div>
            <div className="contacts-row">
                <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email"
                />
                <div className="error email-error"><span><i className="fas fa-exclamation-triangle"></i>Fill in your Email</span></div>
            </div>
            <div className="contacts-row">
            </div>
            <div className="contacts-row">
                <textarea
                    id="message"
                    name="message"
                    placeholder="Message"
                    style={{ height: "200px" }}
                ></textarea>
                <span className="error message-error"><i className="fas fa-exclamation-triangle"></i>Fill in your Message</span>
            </div>
            <div className="contacts-row contacts-button-container">
                <button>Send</button>
            </div>
        </form>
        {alert === ""? "": <AlertContainer alert={alert} display={'flex'} backgroundColor={color}/>}
        </React.StrictMode>
    );
}


export default ContactForm