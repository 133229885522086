import "../../assets/css/datePicker.css";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Make sure this import is included to apply default styles

const DateRangePickerContainer = ({ selectedEvent, dateChange, selectedDate, getTileClassName }) => {


  return (
    <div>
      <Calendar
        onChange={dateChange}
        value={selectedDate}
        tileClassName={getTileClassName}
        onClickDay={dateChange}
      />
    </div>
  );
};

export default DateRangePickerContainer;
