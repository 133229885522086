import React from "react"


const ScrollLink = () => {
    window.addEventListener('scroll', scrollToTop);

    function scrollToTop(e) {
        const scrollLinkButton = document.querySelector('.sect-1-square');
        const val = 100;
        const scrollValue = window.scrollY;
            if(scrollValue <= val ) {
                scrollLinkButton.style.display = 'none';
        } else scrollLinkButton.style.display = 'flex';
    }
    
    return (
        <span className="sect-1-square"
        onClick={()=>{
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
        }}
            style = {{
                position: 'fixed',
                bottom: '50px',
                right: '5%',
                borderRadius: '4px',
                backgroundColor: '#F4AE33',
                color: '#714E92',
                height: '50px',
                width: '50px',
                display: 'none',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '20px',
                zIndex: 2
            }}
        ><i 
            className="fa fa-arrow-up" 
            aria-hidden="true"
        ></i></span>
    );

    
}


export default ScrollLink