import React from "react"
import { useState } from "react"
import '../../assets/css/reg-modal.css'
import axios from "axios"
import AlertContainer from "./alert"





const RegisterationModal = () => {
    const [alert, setAlert] = useState("");
    const [color, setColor] = useState("");

    const regHandleSubmit = (e) => {
        e.preventDefault();
        const [Name, Email, Message] = e.target.elements
        let user = { name: Name.value, email: Email.value, message: Message.value }


        if (nameValidator() && emailValidator() && messageValidator()) {
            axios.post('https://bls-serve.herokuapp.com/user/register', user)
                .then((data) => {
                    document.querySelector(".mod-cover").style.display = 'none';
                    setAlert(data.data);
                    setColor('green');
                    setTimeout(() => setAlert(""), 8000);
                }).catch((err) => {
                    const arrErr = Object.entries(err)[3];
                    if(arrErr[1] === true) {
                        setAlert('Network Error');
                        setColor('rgb(241, 37, 37)');
                        setTimeout(() => setAlert(""), 8000);
                    }
                    if (err.response) {
                        setAlert(err);
                        setColor('rgb(241, 37, 37)');
                        setTimeout(() => setAlert(""), 8000);
                    }
                })
        }


        function nameValidator() {
            if (inputTextisEmpty(Name)) return;
            return true;
        }

        function emailValidator() {
            if (inputTextisEmpty(Email)) return;
            return true;
        }

        function messageValidator() {
            if (inputTextisEmpty(Message)) return;
            return true;
        }

        function inputTextisEmpty(element) {
            if (empty(element.value.trim())) {
                inValid(element);
                return true;
            }
            else {
                valid(element);
                return false;
            };
        }


        function inValid(element) {
            element.nextElementSibling.style.visibility = 'visible';
        }

        function valid(element) {
            element.nextElementSibling.style.visibility = 'hidden';
        }

        function empty(value) {
            if (value === '') return true;
            return false;
        }


        
    }



    return (
        <React.Fragment>
        <section className="mod-cover" onClick={(e) => {
            if (e.target.className !== "mod-cover") {
                return;
            }
            else {
                document.querySelector(".mod-cover").style.display = 'none';
                document.querySelector(".summer-holiday-mini-pop-up").style.display = 'none';
            }
        }}>
            <div className="reg-modal">
                <h3>Be the First</h3>
                <span className="modal-close" onClick={() => {
                    document.querySelector(".mod-cover").style.display = 'none';
                    document.querySelector(".summer-holiday-mini-pop-up").style.display = 'none';
                }}>x</span>
                <form className="modal-form" onSubmit={regHandleSubmit}>
                    <div className="modal-row">
                        <input type="text" id="name" name="name" placeholder="Name" />
                        <div className="error"><span><i className="fas fa-exclamation-triangle"></i> Fill in your Name</span></div>
                    </div>
                    <div className="modal-row">
                        <input type="email" id="email" name="email" placeholder="Email" />
                        <div className="error"><span><i className="fas fa-exclamation-triangle"></i> Fill in your Email</span></div>
                    </div>
                    <div className="modal-row">
                        <textarea
                            id="message"
                            name="message"
                            placeholder="Message"
                            style={{ height: "150px" }}
                        ></textarea>
                        <span className="error message-error"><i className="fas fa-exclamation-triangle"></i>Fill in your Message</span>
                    </div>
                    <div className="modal-row modal-button-container">
                        <button>Send</button>
                    </div>
                        {/*
                            <div className="modal-row modal-link">
                                <span className="reg-link">Already Registered?</span>
                            </div>
                        */}
                </form>
            </div>
        </section>
        {alert === ""? "": <AlertContainer alert={alert} display={'flex'} backgroundColor={color}/>}
        </React.Fragment>
    );
}


export default RegisterationModal;