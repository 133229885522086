import { useState, useLayoutEffect } from "react"
import "../assets/css/services-extended.css"
import Header from "./attactments/header";
import MinHeader from "./attactments/min-header";
import Footer from "./attactments/footer";
import CarouselContainer from "./attactments/quote-carousel";
import babyStars from "../assets/img/baby-stars.png"
import RegisterationModal from "./attactments/registerModal";
import ScrollLink from "./attactments/scrollLink";




const ServicesExtended2 = () => {

    const [Home, setHome] = useState('none');

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <div className="services-2">
            <Header setHome={setHome} />
            <MinHeader Home={Home} />
            <section className="serv-extend-container">
                <div className="showcase-text">
                    <h2>Baby Stars</h2>
                    <h1>Age: 3 to 12 months</h1>
                </div>
            </section>

            <section className="serv-extend-sect-2">
                <div className="serv-sect-2-container-1">
                    <div className="services-extend-text-container">
                        <p>
                             We understand that not all families will be in the same situation and some parents/carers will need our services earlier than others. This is why we have a dedicated baby team who are on hand to nurture, care, and help your baby develop in their early stages of life. We have a comfortable baby area in which they will be provided with varying sensory opportunities, including tummy time. We will work with you to provide the nutrition you desire for your child, including working out a feeding schedule for babies on formula/breast milk.
                        </p>

                    </div>
                    <div className="serv-sect-2-img-container services-extend-sect-1-img-case">
                        <img src={babyStars} alt="babyStars" />
                    </div>
                </div>
            </section>
            <CarouselContainer />
            <RegisterationModal/>
            <ScrollLink/>
            <Footer />
        </div>
    );
}

export default ServicesExtended2;