import { useState, useLayoutEffect } from "react"
import Footer from "./attactments/footer";
import Header from "./attactments/header"
import MinHeader from "./attactments/min-header";
import "../assets/css/home.css"
import kidsBeingCreative from "../assets/img/kids-being-creative-344554635.png"
import kids_classroom_brees_little_kids from "../assets/img/kids_classroom_brees_little_kids.jpg"
import teacherGuidingChildren from "../assets/img/teacher-guiding-children-brees-little-stars-2343534345345.png"
import ScrollLink from "./attactments/scrollLink"
import CarouselContainer from "./attactments/quote-carousel";
import RegisterationModal from "./attactments/registerModal";
// import SummerLiveContainer from "./attactments/summerLiveUpdate";
import { Link } from "react-router-dom"
import CountdownTimer from "./attactments/countdownTimer";
// import opt1 from "../assets/img/slide/1.jpg";
// import opt2 from "../assets/img/slide/2.jpg"; images for slideshow
// import opt3 from "../assets/img/slide/3.jpg";
//import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import CustomModal from "./attactments/modal";




const Home = () => {

    const [homeStore, setHomeStore] = useState('none');
    const [show, setShow] = useState(true);
   // const history = useHistory();

    // const minutesToAdjust = 2;
    // const millisecondsPerMinute = 60000;
    // const originalDate = new Date();
//    const modifiedDate = new Date(originalDate.valueOf() + (minutesToAdjust * millisecondsPerMinute));

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", marginRight:'10rem' }}
            onClick={onClick}
          />
        );
      }
      
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block", marginLeft:'5rem', zIndex:'200' }}
            onClick={onClick}
          />
        );
      }
      

    // React Slick Carousel
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 15000,
        pauseOnHover: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />  
    };


    //For the carousel
    // const [index, setIndex] = useState(0);

    // const handleSelect = (selectedIndex, e) => {
    //     setIndex(selectedIndex);
    // };

    


    return (
        <div className="Home">
            <Header setHome={setHomeStore} />
            <MinHeader Home={homeStore} />

           <div className="showcase">
           <div className="showcase_frame">
                     <div>
                     <p className="s1">Welcome to Bree’s Little Stars</p>
                        <h1>
                            Our Happy Place
                        </h1>
                        <p className="s2">We offer day care, after-school and holiday club services for your little stars.</p>
                        <div className="showcase-buttons">
                            <Link className="showcase-link" to="/about">Click to find out more</Link>
                            <Link className="showcase-link showcase-link-2" style={{backgroundColor: "#714E92"}} to="/calendar">Upcoming Events</Link>
                        </div>
                     </div>
                </div>

           <Slider
             {...settings}
             >
                <div
                    className="slideframe opt1"
                    // onClick={()=>history.push({
                    //     pathname: '/calendar',
                    //     state: {
                    //         scrollpath: '700'
                    // }})}
                >
                </div>
                <div
                    className="slideframe opt3"
                    // onClick={()=>history.push({
                    //     pathname: '/calendar',
                    //     state: {
                    //         scrollpath: '2480'
                    //     }})}
                >
                </div>

                <div
                    className="slideframe opt4"
                    // onClick={()=>history.push({
                    //     pathname: '/calendar',
                    //     state: {
                    //         scrollpath: '2480'
                    //     }})}
                >
                </div>

                <div
                    className="slideframe opt6"
                    // onClick={()=>history.push({
                    //     pathname: '/calendar',
                    //     state: {
                    //         scrollpath: '2480'
                    //     }})}
                >
                </div>

                <div
                    className="slideframe opt7"
                    // onClick={()=>history.push({
                    //     pathname: '/calendar',
                    //     state: {
                    //         scrollpath: '2480'
                    //     }})}
                >
                </div>

                </Slider>
           </div>
                    

          

            <section className="sect-1">
                <div className="sect-1-frame1">
                    <div className="sect-1-img-div">

                        <img src={teacherGuidingChildren} alt="teacher teaching children" />

                    </div>
                </div>

                <div className="sect-1-frame2">
                    <h2>We will take care of your child</h2>
                    <p>Bree’s Little Stars is a place for your child to be nurtured and cared for, enabling them to develop into the best possible version of themselves.</p>
                    <p>We ensure a safe, fun and loving environment for your child where they can learn whilst playing and create wonderful memories. At Bree’s Little Stars, your child will be encouraged to shine, in everything that they do.</p>

                    {/*
                        <ul className="fa-ul">
                            <li><span className="fa-li"><i className="fas fa-star star-one"></i></span>Literacy</li>
                            <li><span className="fa-li"><i className="fas fa-star star-two"></i></span>Mathematics</li>
                        </ul>

                        <ul className="fa-ul">
                            <li><span className="fa-li"><i className="fas fa-star star-three"></i></span>Expressive arts and design</li>
                            <li><span className="fa-li"><i className="fas fa-star star-four"></i></span>Understanding the world</li>
                        </ul>
                    */}

                </div>
                {/*<i className="fa fa-star" aria-hidden="true"></i>*/}
            </section>

            <section className="sect-2">
                <div className="sect-2-frame1">
                    <p>Play, Learn, Grow</p>

                    <h2>Our Services</h2>
                    <p>At Bree’s Little Stars, we put a little magic into all our children.</p>


                    <ul className="fa-ul">
                        <li><span className="fa-li"><i className="fas fa-star star-one"></i></span>Nursery and Pre-School: 3 months to 5 years</li>
                        <li><span className="fa-li"><i className="fas fa-star star-two"></i></span>After School Club: 4 years plus</li>
                        <li><span className="fa-li"><i className="fas fa-star star-two"></i></span>All Day Holiday Club: 4 years plus</li>
                    </ul>

                    <Link
                        to="/register"
                        className="global-button"
                    > Register Your Child </Link>
                </div>

                <div>
                    <div className="sect-2-img">
                        <div className="img">
                            <img src={kids_classroom_brees_little_kids} alt="kid" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="sect-3">
                <h2>Why Bree’s Little Stars?</h2>
                <span className="sect-3-list">
                    <span><i className="fas fa-star star-one"></i> You learn and explore</span>
                    <span><i className="fas fa-star star-one"></i> You learn and grow</span>
                    <span><i className="fas fa-star star-one"></i> A stimulating environment</span>
                </span>


                <div className="sect-3-grid">
                    <div className="sect-3-grid-child-div">
                        <img src={kidsBeingCreative} alt="kids_being_creative" />
                    </div>

                    <div className="sect-3-frame2">
                        <p>At Bree’s Little Stars, we believe that every child matters and every child has great potential. We believe that every child deserves the best possible start in life and as such, we support each child so that they can achieve their potential. We believe in and value the uniqueness of each child we serve, and we provide an uncompromised quality service for them.</p>
                    </div>
                </div>

                <div className="sect-3-frame-3">
                    <p>Call us on <a href="tel:+07961713476">07961713476</a></p>
                    <Link
                        to="/register"
                        className="header-button"
                    > Register Your Child </Link>
                </div>
            </section>
            <CarouselContainer />
            <section className="sect-4-map-container">
                <div className="home-map-glass">
                    <div>
                        <div className="home-glass-child-div-2">
                            <h1>Contact Us</h1>
                            <p>Send us a message.</p>
                            <p><i className="fas fa-map-marker-alt home-marker"></i>Greenwich, SE10</p>
                            <p><i className="far fa-envelope home-envelope"></i> <a href="mailto:info@breeslittlestars.co.uk">info@breeslittlestars.co.uk</a></p>
                            <p><i className="fas fa-mobile-alt home-moblie"></i> <a href="tel:+07961 713476">07961 713476</a></p>
                            <button className="global-button" onClick={() => {
                                document.querySelector(".home-map-glass").classList.remove('fadeIn');
                                document.querySelector(".home-map-glass").classList.add('fadeOut');
                                document.querySelector(".show-form").classList.add('fadeIn');
                                setTimeout(() => {
                                    document.querySelector(".home-map-glass").style.zIndex = -1;
                                }, 3000);
                            }}>View Map</button>
                        </div>
                    </div>
                </div>
                <button className="show-form global-button" onClick={() => {
                    document.querySelector(".home-map-glass").style.zIndex = 0;
                    document.querySelector(".home-map-glass").classList.remove('fadeOut');
                    document.querySelector(".home-map-glass").classList.add('fadeIn');
                    document.querySelector(".show-form").classList.remove('fadeIn');
                    document.querySelector(".show-form").classList.add('fadeOut');
                    document.querySelector(".home-map-glass").style.zIndex = 0;
                }}>Back to form</button>
                <div className="mapouter">
                    <div className="gmap_canvas">
                        <iframe title="map" className="map-frame home-map" id="gmap_canvas" src="https://maps.google.com/maps?q=Greenwich%20High%20Road,%20London,%20SE10%208JA&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} />
                        <a href="https://www.whatismyip-address.com" style={{ display: 'none' }}>map</a>
                        <br />
                        <a href="https://www.embedgooglemap.net" style={{ display: 'none' }}>map</a>

                    </div>
                </div>
            </section>
            {/* {show === true ? <SummerLiveContainer setShow={setShow} /> : ""} */}
            <Link
                to={"/calendar"}
                className="summer-holiday-mini-pop-up"
                // onClick={() => {
                //     localStorage.removeItem('popStatus');
                //     document.querySelector(".summer-holiday-mini-pop-up").style.display = 'none';
                //     setShow(true);
                //     // setNoScroll(true);
                //     document.querySelector('body').style.overflow = 'hidden';
                //     localStorage.setItem('popStatus', JSON.stringify({ bool: true, time: modifiedDate, interact: false }));
                // }}
            >
                <p className="mob-part-mini-pop-up-title">Coming Up</p>
                <p className="mini-pop-up-title">Upcoming Event</p>
                <CountdownTimer /> 
            </Link>
            <RegisterationModal />
            { show && <CustomModal setShow={setShow} />}
            <ScrollLink />
            <Footer />
        </div>
    );
}


export default Home;