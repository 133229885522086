import { useState } from "react";
// import { Link } from "react-router-dom"
import "../assets/css/calendar.css";
import Header from "./attactments/header";
import MinHeader from "./attactments/min-header";
import Footer from "./attactments/footer";
import RegisterationModal from "./attactments/registerModal";
import DateRangePickerContainer from "./attactments/datePicker";
import ScrollLink from "./attactments/scrollLink";
// import aone from "../assets/img/slide/aone.jpg";
// import atwo from "../assets/img/slide/atwo.jpg";
// import athree from "../assets/img/slide/athree.png";
// import afour from "../assets/img/slide/afour.jpg";
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
// import CalendarModal from "./attactments/calendar_modal";
//import { EffectCards } from "swiper";

const Calendar = (props) => {
  const [calendarStore, setCalendarStore] = useState("none");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [events] = useState([
    {
      startDate: new Date(2024, 6, 29),
      endDate: new Date(2024, 7, 22), 
      title: "Holiday Food and Fun! Holiday Club",
      description: "29th July - 22nd August",
    },
    // Add more events here
  ]);

  const dateChange = (date) => {
    setSelectedDate(date);
  };

  const isDateInRange = (date, startDate, endDate) => {
    const currentDate = new Date();
    return date >= startDate && date <= endDate && currentDate <= endDate;
  };

  const getTileClassName = ({ date, view }) => {
    if (view === 'month') {
      return events.some(event => isDateInRange(date, event.startDate, event.endDate)) ? 'highlight' : null;
    }
    return null;
  };

  const getEventForDate = (date) => {
    return events.find(event => isDateInRange(date, event.startDate, event.endDate));
  };

  const selectedEvent = getEventForDate(selectedDate);


  return (
    <>
      <Header setHome={setCalendarStore} />
      <MinHeader Home={calendarStore} />
      <section className="calendar-sect-1">
        <h1>Calendar</h1>
      </section>

      <section className="calender-sect-2">
        <div className="cal-sect-2-calendar">
          <div>
            <DateRangePickerContainer
              selectedEvent={selectedEvent}
              dateChange={dateChange}
              selectedDate={selectedDate}
              getTileClassName={getTileClassName}
            />
          </div>
        </div>

        <div className="cal-sect-2-date-note">
          <h1>Important Dates</h1>
          <p>Select highlighted date to see the event below.</p>
          <div className="screen-date">
            {/*<p>Summer Holiday – 26th July 2021 to 25th August 2021</p>*/}
            {selectedEvent && (
              <div className="event-details">
                <h2>{selectedEvent.title}</h2>
                <p>{selectedEvent.description}</p>
                {/* <Link to="/summer_holiday_club">View more</Link> */}
              </div>
            )}
          </div>

          <div className="mob-date">
            {/*
                         <p className="mob-date-title">
                            Summer Holiday
                         </p>

                           <p className="mob-date-content">
                            26th July 2021 to 25th August 2021
                         </p>
                         
                         */}
          </div>
          {/* <CalendarModal/> */}
          <div className="mob-date">
            {/*<p>Summer Holiday – 26th July 2021 to 25th August 2021</p>*/}
            {selectedEvent && (
              <div className="event-details">
                <h2>{selectedEvent.title}</h2>
                <p>{selectedEvent.description}</p>
                {/* <Link to="/summer_holiday_club">View more</Link> */}
              </div>
            )}
          </div>
        </div>
      </section>

      {/* <section className="serv-sect-6">
        <div className="serv-sect-2-container-1">
          <div>
            <h1>Holiday Summer Club</h1>
            <p>
              Make awesome memories this summer with our fun-packed summer club!
              We've got activities like forest exploration, science workshops, cinema trips, guided tours and many more lined up, from just £25 per day, 8am to 5.30pm
            </p>

             <p>
                Spaces are limited, Register now to book your space.
             </p>

            <div className="sect-4-div-to-link">
              <a
                className="global-button"
                href="/register"
                rel="noopener noreferrer"
              >
                Book now
              </a>


              <a
                        href="/register"
                        rel="noopener noreferrer"
                        className="global-button"
                        style={{marginLeft:`1rem`}}
              > Register Your Child </a>


            </div>
          </div>

          <div className="serv-sect-2-img-container">
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards]}
              className="mySwiper floating"
            >
              <SwiperSlide>
                <img src={aone} alt="bree little stars summer holiday" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={atwo} alt="bree little stars summer holiday" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

     

      <section className="serv-sect-6" style={{ backgroundColor: "#6fa8db" }}>
        <div className="serv-sect-2-container-1">
          <div
            style={{
              display:'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <h1>Holiday Food and Fun</h1>
            <p>
              Make this a summer holiday to remember! 
              Your child may be eligible. Free spaces are available across the borough for eligible school children.
              Enquire for more info- <a href="mailto:club.holiday@breeslittlestars.co.uk">club.holiday@breeslittlestars.co.uk</a>
            </p>

          </div>

          <div className="serv-sect-2-img-container">
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards]}
              className="mySwiper floating"
            >
              <SwiperSlide>
                <img src={afour} alt="bree little stars food and fun" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={athree} alt="bree little stars food and fun" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section> */}

      {/* <section className="serv-sect-6" style={{ backgroundColor: "#c8e7b8" }}>
        <div className="serv-sect-2-container-1">
          <div>
            <h1>After School Club</h1>
            <p>
                Our After-school club offers children an opportunity to learn new skills, discover new interest, make new friends, and have an awesome time. 
            </p>

            <p>
              We Strive to encourage the values that they are already learning at home and in school i.e. Respect, Responsibility, and self-confidence. Our dedicated and experienced staff strives to bring out the best in children whilst keeping them safe and valued. 
              Register now to book your space.
            </p>

            <div className="sect-4-div-to-link">
              <a
                className="global-button"
                href="https://cheqdin.com/app/portal/public/online-booking/Bree%27s%20Little%20Stars/MTEwNg=="
                target="_blank"
                rel="noopener noreferrer"
              >
                Book now
              </a>

              <a
                        href="https://cheqdin.com/app/portal/public/registrations/Bree%27s%20Little%20Stars/MTEwNg=="
                        target="_blank"
                        rel="noopener noreferrer"
                        className="global-button"
                        style={{marginLeft:`1rem`}}
              > Register Your Child </a>
            </div>
          </div>

          <div className="serv-sect-2-img-container">
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards]}
              className="mySwiper floating opt2"
            >
              <SwiperSlide>
                <img src={opt2} alt="bree little stars summer holiday" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>


      <section className="serv-sect-6" style={{backgroundColor:`#714E92`}}>
        <div className="serv-sect-2-container-1">
          <div>
            <h1 style={{color:`#ffffff`}}>October Half Term</h1>
            <p style={{color:`#ffffff`}}>
              One week of fun learning activities. Treat your child to a week of enriching activities slated for the Half-term break in October.
            </p>

            <div className="sect-4-div-to-link">
              <a
                className="global-button"
                href="https://cheqdin.com/app/portal/public/online-booking/Bree%27s%20Little%20Stars/MTEwNg=="
                target="_blank"
                rel="noopener noreferrer"
              >
                Book now
              </a>

              <a
                        href="https://cheqdin.com/app/portal/public/registrations/Bree%27s%20Little%20Stars/MTEwNg=="
                        target="_blank"
                        rel="noopener noreferrer"
                        className="global-button"
                        style={{marginLeft:`1rem`}}
              > Register Your Child </a>
            </div>
          </div>

          <div className="serv-sect-2-img-container">
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards]}
              className="mySwiper floating opt2"
            >
              <SwiperSlide>
                <img src={opt3} alt="bree little stars October Half Term" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section> */}

      <RegisterationModal />
      <ScrollLink />
      <Footer />
    </>
  );
};

export default Calendar;
