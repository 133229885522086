import { useState } from "react";
import '../../assets/css/carousel.css'
import { Carousel } from "react-bootstrap"




const CarouselContainer = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <section className="sect-4">
            <div className="sect-4-child-div">
                <i className="fas fa-quote-left"></i>
                <h2>What Parents Think</h2>
                <Carousel activeIndex={index} onSelect={handleSelect} indicators={true} interval={5000}>
                    <Carousel.Item>
                        <p>
                             I just want to thank you very much for providing stability for my daughter.  
                       </p>

                        <p>
                              She has been through a lot of turmoil in the last year, and I feel blessed to have a place like yours to give her the care and love that every child needs.  
                        </p>
                        
                        <p>
                              I am so glad the school recommended Bree’s Little Stars for my daughter. She always looks forward to coming there and even when other alternative childcare arrangements have been put in place, she will ask “is Bree’s Little’s Stars open today?  
                        </p>

                        <p>
                            Thank you for all you do.
                        </p>
                        <h5>BY MBT</h5>
                    </Carousel.Item>

                    <Carousel.Item>
                        <p>
                              My son attended Bree's Little Stars from the age of 7 months. The care he received there was exceptional, it truly was a home away from home setting. Ms Bree and her team became like a family to him, we always felt that he was in a safe space as well as a place where he could learn and develop key skills. He also formed great bonds with the other kids who attended there. We had to move when it was time for my son to go to Reception however, he still visits on school holidays as he misses it dearly and we know it's a service we can count on.  
                       </p>

                       <p>
                              I would highly recommend BLS's service to parents/guardians. The fun, loving and flexible environment was just what our family needed.
                       </p>
                        <h5>Mrs. P</h5>
                    </Carousel.Item>

                    <Carousel.Item>
                        <p>
                            Leaving my 3-month-old baby to go to back work, was not easy for me. It was emotional, affected my health and really did take its toll on me, especially as I was still exclusively breastfeeding!! However, from the moment I met Bree and her team, all my fears (and there were plenty of them) disappeared one by one over the course of a few weeks. I needed to find a setting that would understand what it was like for me to leave my baby.  
                       </p>

                       <p>
                            Understand the pressure I was under, understand my particular style of doing things and understand the ins and outs of looking after such a young baby. Bree’s Little Stars have ensured the journey has been smooth. They have made the journey pleasant and have cared for my daughter so beautifully. I literally couldn’t have asked for anything else. I am extremely humbled and have taken the time to write this review so that if there are other mums out there who may have to leave their precious little one, then please do consider Bree’s Little Stars - you won’t regret it.
                       </p>

                        <h5>MRs. O</h5>
                    </Carousel.Item>
                </Carousel>
            </div>
        </section>
    );
}


export default CarouselContainer;