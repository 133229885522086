import boyThumbingUp from '../assets/img/pre-school/boy-thumbing-up-brees-little-stars-34547686774.jpg'
import kidReading from '../assets/img/pre-school/kid-reading-brees-little-stars-123423556645.jpg'
import teacherTeaching from '../assets/img/pre-school/teacher-teaching-kidsbrees-little-stars.jpg'
import kidsWithALantern from '../assets/img/pre-school/kids-with-a-lantern-brees-little-stars-4568765432465762.jpg'
import kidBeingCreative from '../assets/img/pre-school/kid-being-creative-brees-little-stars-435756854.jpg'
import kidBeingCreative1 from '../assets/img/pre-school/kid-being-creative-brees-little-stars-3546798076.jpg'
import akidWithWheelBarrows from '../assets/img/pre-school/a-kid-with-the-wheelbarrow-brees-little-stars.jpg'
import boyBeingCreative from '../assets/img/pre-school/boy-being-creative-brees-little-stars.jpg'
import girlWithMud from '../assets/img/pre-school/girl-with-mud-brees-little-stars.jpg'
import kidAndArtPreSchool from '../assets/img/pre-school/kid-and-art-brees-little-stars435678762.jpg'
import kidBeingCreative1PreSchool from '../assets/img/pre-school/kid-being-creative-243576854.jpg'
import kidBeingCreative2PreSchool from '../assets/img/pre-school/kid-being-creative-brees-little-stars-234356787.jpg'
import kidBeingCreative4PreSchool from '../assets/img/pre-school/kid-being-creative-brees-little-stars-987334467678964.jpg'
import kidBreesLittleStars from '../assets/img/pre-school/kid-brees-little-stars-456786745.jpg'
import kidInASurface from '../assets/img/pre-school/kid-in-a-closet-brees-little-stars.jpg'
import kidHowingHands from '../assets/img/pre-school/kid-showing-hands-brees-little-kids.jpg'
import kidsKneading from '../assets/img/pre-school/kids-kneading-brees-little-stars.jpg'
import kidsPlayingPreschool1 from '../assets/img/pre-school/kids-playing-brees-little-stars4546577563453545.jpg'
import kidsWashingHands from '../assets/img/pre-school/kid-washing-hands-brees-little-stars.jpg'
import kidsWithLantern from '../assets/img/pre-school/kid-with-lantern-brees-little-stars.jpg'
import PreSchoolBreesLittleStars from '../assets/img/pre-school/pre-school-brees-little-stars.jpg'
import teacherGuidingKid from '../assets/img/pre-school/teacher-guiding-kid-brees-little-stars.jpg'
import buildingBlocks from '../assets/img/pre-school/building-blocks-together-brees-little-stars-32434634.jpg'
import happyGirl from '../assets/img/pre-school/happy-girl-brees-little-stars-34354767876.jpg'
import artIsFun from '../assets/img/pre-school/art-is-fun.jpg'

import babyBreesLittleStars from '../assets/img/nursery/baby-bree-little-stars-2365212356865432.jpg'
import babyPlayingWithToy from '../assets/img/nursery/baby-playing-with-a-toy-brees-little-stars.jpg'
import kidsPlaying from '../assets/img/nursery/kid-playing-brees-little-stars-13245465678768675.jpg'
import kidsPlaying1 from '../assets/img/nursery/kids-playing-brees-little-stars-32356754.jpg'
import kidsPlaying2 from '../assets/img/nursery/kids-playing-brees-little-stars45678988767.jpg'



import HolidayClubAtTheZoo from '../assets/img/holiday-club/holiday-club-at-the-zoo-brees-little-stars.jpg'
import KidAndArt from '../assets/img/holiday-club/kid-and-art-brees-little-stars3456786342.jpg'
import KidStartingAFire from '../assets/img/holiday-club/kid-starting-a-fire.jpg'
import KidPlayingInTheWoods from '../assets/img/holiday-club/kids-playing-in-the-woods.jpg'
import KidsBreeslittlestars from '../assets/img/holiday-club/kid-brees-little-starsadd6789678685858568.jpg'



import kidsFarming from '../assets/img/holiday-club/2-kids-farming.jpg'
import boyCutting from '../assets/img/holiday-club/boy-cutting-545356876575.jpg'
import boyHangingAShirt from '../assets/img/holiday-club/boy-hanging-a-shirt.jpg'
import boyWithLaptop from '../assets/img/holiday-club/boy-with-laptop.jpg'
import breesLittleStarsAtTheBus from '../assets/img/holiday-club/brees-little-stars-at-the-bus.jpg'
import breesLittleStarsHavingFun from '../assets/img/holiday-club/brees-little-stars-having-fun-3456877974.jpg'
import breesLittleStarsPlaying from '../assets/img/holiday-club/brees-little-stars-playing-213456787524.jpg'
import breesLittleStarsTeaParty from '../assets/img/holiday-club/brees-little-stars-tea-party-32546781324.jpg'


import girlHoldingABaloonBrees from '../assets/img/holiday-club/girl-holding-a-baloon-brees-little-stars.jpg'
import girlPaintingBreeLittleStars from '../assets/img/holiday-club/girl-painting-brees-little-stars.png'
import holidayEvironsBreesLittleStars from '../assets/img/holiday-club/holiday-club-environs-brees-little-stars-3456780875.jpg'
import kidCleaningBreesLittleStars from '../assets/img/holiday-club/kid-cleaning-brees-little-stars.jpg'
import kidPlaying4 from '../assets/img/holiday-club/kid-playing-4-53456456792355.jpg'
import kidPlaying5 from '../assets/img/holiday-club/kid-playing-325467698546234465.jpg'
import kidPlaying12 from '../assets/img/holiday-club/kid-playingfgify7yd78fyd89fu.jpg'
import kidPlayingOnIce from '../assets/img/holiday-club/kid-playing-on-ice.jpg'
import kidRidingOnABike from '../assets/img/holiday-club/kid-riding-a-bicycle.jpg'
import kidAtTheFarm from '../assets/img/holiday-club/kids-at-the-farm.jpg'
import kidAtTheZoo from '../assets/img/holiday-club/kids-at-the-zoo-345675234567.jpg'
import kidsBeingCreative from '../assets/img/holiday-club/kids-being-creative-breeslittlte-kids-24365342.jpg'
import kidsClimbing from '../assets/img/holiday-club/kids-climbing.jpg'
import kidsExploring from '../assets/img/holiday-club/kids-exploring-456786324.jpg'
import kidsHavingFun2 from '../assets/img/holiday-club/kids-having-fun-brees-little-stars-345677874532.jpg'
import kidsInAPlay from '../assets/img/holiday-club/kids-in-a-play-shelter.jpg'
import kidsLearning from '../assets/img/holiday-club/kids-learning.jpg'
import kidsLearning2 from '../assets/img/holiday-club/kids-learning-214567654132.jpg'
import theMeasuringKidBeingCretive from '../assets/img/holiday-club/the-measuring-kid-being-creative.jpg'
import kidsPlayingWithToySeaAnimals from '../assets/img/holiday-club/kids-playing-with-toy-sea-animals-6534534677.jpg'
import kidsLearning3 from '../assets/img/holiday-club/kids-plearning-24564646464214.jpg'
import kidsWahingFruits from '../assets/img/holiday-club/kids-washing-fruits.jpg'
import kidsWahingTheirHand from '../assets/img/holiday-club/kids-washing-their-hand-brees-little-stars-3523987655879098564.jpg'
import kidUnderAWashingSink from '../assets/img/holiday-club/kid-under-a-washing-sink-356478776523.jpg'
import kidWalkingDownAPath from '../assets/img/holiday-club/kid-walking-down-a-path.jpg'
import kidWithTheOnion from '../assets/img/holiday-club/kid-with-the-onion.jpg'
import kidsWithAToy from '../assets/img/holiday-club/kis-with-a-toy-54678767763254678.jpg'
import kidswithAnUmbrella from '../assets/img/holiday-club/littlle-kids-with-an-umbrella.jpg'
import photoOfABaby from '../assets/img/holiday-club/photo-of-a-baby-girl.jpg'
import plantNursery from '../assets/img/holiday-club/plant-nursery-556588765646.jpg'
import showingKidHowToGrate from '../assets/img/holiday-club/showing-kid-how-to-grate.jpg'
import teacherGuidingKidHowToStartAFire from '../assets/img/holiday-club/teacher-guiding-kid-on-how-to-start-a-fire.jpg'
import theMeasuringKid from '../assets/img/holiday-club/the-measuring-kid.jpg'
import holidayLessonComputer from '../assets/img/holiday-club/holiday-lesson-computer-34345436.jpg'
import holidayClubPathFinder from '../assets/img/holiday-club/brees-little-stars-pathfinder-3453453.jpg'
import holidayClubInTheForest from '../assets/img/holiday-club/holidayclub-in-the-forest-brees-little-stars.jpg'
import holidayClubInTheForest1 from '../assets/img/holiday-club/holiday-club-in-the-forest-brees-little-stars-53535353.jpg'
import holidayClubInTheForest2 from '../assets/img/holiday-club/holiday-in-the-forest-brees-little-stars-54646346535.jpg'
import holidayClubInTheForest3 from '../assets/img/holiday-club/holiday-in-the-forest-45553.jpg'
import kidWithUmbrella from '../assets/img/holiday-club/kid-with-umbrella-brees-little-stars.png'




import KidPlayingWithADoll from '../assets/img/after-school-club/boy-with-baby-doll.jpg'
import kidsPlayingWithADoll from '../assets/img/after-school-club/kid-playing-with-a-doll-brees-little-stars.jpg'
import breesLittlekidsAfterSchoolClub from '../assets/img/after-school-club/brees-little-stars-after-school-club-55467897256575.jpg'
import breesLittlekidsAfterSchoolClub2 from  '../assets/img/after-school-club/teacher-and-kid-brees-little-stars.jpg'
import boyWithAPoliceHat from  '../assets/img/after-school-club/boy-with-a-police-hat-256787214yjee5.jpg'
import breesLittleKidsClassroom from  '../assets/img/after-school-club/brees-little-kids-classroom-4546.jpg'
import kidsBaking from  '../assets/img/after-school-club/kids-baking-brees-little-stars.jpg'
import kidLearningAfterSchoolClub from  '../assets/img/after-school-club/kids-learning-brees-little-stars-3456789762.jpg'
import kidLearningAfterSchoolClub2 from  '../assets/img/after-school-club/kids-learning-brees-little-stars-4546787685.jpg'
import kidPlayinAfterSchoolClub from  '../assets/img/after-school-club/kids-playing-brees-little-stars-5643f45rv56.jpg'
import kidReadingAfterSchoolClub from  '../assets/img/after-school-club/kids-reading-brees-little-stars.jpg'
import chefGirl from '../assets/img/after-school-club/chef-girl-32142343543543.jpg'
import chefKid from '../assets/img/after-school-club/chef-kid.jpg'
import chefKid2 from '../assets/img/after-school-club/chef-kid-345679087.jpg'

import teaCup from '../assets/img/all/brees-little-stars-767678566656.jpg'
import breeLittleStarsChairs from '../assets/img/all/brees-little-stars-767678566656.jpg'
import breeLittleStarsClassroom from '../assets/img/all/brees-little-stars-classroom.jpg'
import breeLittleStarsChristmasTree from '../assets/img/all/brees-little-starsclassroom-section.jpg'
import paintedUmbrella from '../assets/img/all/brees-little-stars-painted-umbrella.jpg'
import breeLittleStars from '../assets/img/all/brees-little-stars-345678654.jpg'
import breeLittleStarsClassroom1 from '../assets/img/all/classroom-brees-little-stars-34567751.jpg'


export const data =  {
    categories: ['All', 'Holiday Club', 'Pre-School', 'Nursery', 'AfterSchool Club'],
    imgData: [
        {
            title: 'kidWithUmbrella',
            path:kidWithUmbrella,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kid-brees-little',
            path:KidsBreeslittlestars,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'breesLittleStarsTeaParty',
            path:breesLittleStarsTeaParty,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kidsHavingFun2',
            path:kidsHavingFun2,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kidsWahingTheirHand',
            path:kidsWahingTheirHand,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kids-playing',
            path:kidsPlaying,
            category: 'nursery-and-pre-school',
            subCategory: 'nursery'
        },
        {
            title: 'happy girl',
            path:happyGirl,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kidswithAnUmbrella',
            path:kidswithAnUmbrella,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kid-reading',
            path:kidReading,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'baby-playing-with-a-toy',
            path:babyPlayingWithToy,
            category: 'nursery-and-pre-school',
            subCategory: 'nursery'
        },
        {
            title: 'teacher-teaching',
            path:teacherTeaching,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kids-playing',
            path:kidsPlaying2,
            category: 'nursery-and-pre-school',
            subCategory: 'nursery'
        },
        {
            title: 'kids-with-a-lantern',
            path:kidsWithALantern,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'art is fun',
            path:artIsFun,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kid-being-creative',
            path:kidBeingCreative,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kids-playing',
            path:kidsPlaying1,
            category: 'nursery-and-pre-school',
            subCategory: 'nursery'
        },
        {
            title: 'kid-being-creative',
            path:kidBeingCreative1,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'AkidWithWheelBarrows',
            path:akidWithWheelBarrows,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kidPlaying5',
            path:kidPlaying5,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'photoOfABaby',
            path:photoOfABaby,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'boyBeingCreative',
            path:boyBeingCreative,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kidUnderAWashingSink',
            path:kidUnderAWashingSink,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'GirlWithMud',
            path:girlWithMud,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kidRidingOnABike',
            path:kidRidingOnABike,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kidAndArtPreSchool',
            path:kidAndArtPreSchool,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kidBeingCreative1PreSchool',
            path:kidBeingCreative1PreSchool,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kidBeingCreative2PreSchool',
            path:kidBeingCreative2PreSchool,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'breesLittleStarsPlaying',
            path:breesLittleStarsPlaying,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kidWalkingDownAPath',
            path:kidWalkingDownAPath,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kidBeingCreative4PreSchool',
            path:kidBeingCreative4PreSchool,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            path:kidBreesLittleStars ,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'showingKidHowToGrate',
            path:showingKidHowToGrate,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kidInASurface',
            path:kidInASurface,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kidHowingHands',
            path:kidHowingHands,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kidsKneading',
            path:kidsKneading,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kidsPlayingPreschool1',
            path:kidsPlayingPreschool1,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kidsWashingHands',
            path:kidsWashingHands,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'kidsWithLantern',
            path:kidsWithLantern,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'PreSchoolBreesLittleStars',
            path:PreSchoolBreesLittleStars,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'teacherGuidingKid',
            path:teacherGuidingKid,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title:'buildingBlocks',
            path: buildingBlocks,
            category: 'nursery-and-pre-school',
            subCategory: 'pre-school'
        },
        {
            title: 'baby-bree-little-stars',
            path:babyBreesLittleStars,
            category: 'nursery-and-pre-school',
            subCategory: 'nursery'
        },
        {
            title: 'holiday-club-at-the-zoo',
            path:HolidayClubAtTheZoo,
            category: 'holiday-club'
        },
        {
            title: 'kid-and-art',
            path:KidAndArt,
            category: 'holiday-club'
        },
        {
            title: 'KidStartingAFire',
            path:KidStartingAFire,
            category: 'holiday-club'
        },
        {
            title: 'kid-balancing-on-a pole',
            path:KidPlayingInTheWoods,
            category: 'holiday-club'
        },
        {
            title: 'holidayClubInTheForest3',
            path:holidayClubInTheForest3,
            category: 'holiday-club'
        },
        {
            title: 'kidFarming',
            path:kidsFarming,
            category: 'holiday-club'
        },
        {
            title: 'holidayClubPathFinder',
            path:holidayClubPathFinder,
            category: 'holiday-club'
        },
        {
            title: 'boyCutting',
            path:boyCutting,
            category: 'holiday-club'
        },
        {
            title: 'boyHangingAShirt',
            path:boyHangingAShirt,
            category: 'holiday-club'
        },
        {
            title: 'boyWithLaptop',
            path:boyWithLaptop,
            category: 'holiday-club'
        },
        {
            title: 'holidayClubInTheForest2',
            path:holidayClubInTheForest2,
            category: 'holiday-club'
        },
        {
            title: 'breesLittleStarsAtTheBus',
            path:breesLittleStarsAtTheBus,
            category: 'holiday-club'
        },
        {
            title: 'breesLittleStarsHavingFun',
            path:breesLittleStarsHavingFun,
            category: 'holiday-club'
        },
        {
            title: 'holidayClubInTheForest',
            path:holidayClubInTheForest,
            category: 'holiday-club'
        },
        {
            title: 'girlHoldingABaloonBrees',
            path:girlHoldingABaloonBrees,
            category: 'holiday-club'
        },
        {
            title: 'holidayClubInTheForest1',
            path:holidayClubInTheForest1,
            category: 'holiday-club'
        },
        {
            title: 'girlPaintingBreeLittleStars',
            path:girlPaintingBreeLittleStars,
            category: 'holiday-club'
        },
        {
            title: 'holidayEvironsBreesLittleStars',
            path:holidayEvironsBreesLittleStars,
            category: 'holiday-club'
        },
        {
            title: 'kidCleaningBreesLittleStars',
            path:kidCleaningBreesLittleStars,
            category: 'holiday-club'
        },
        {
            title: 'kidPlaying4',
            path:kidPlaying4 ,
            category: 'holiday-club'
        },
        {
            title: 'kidPlaying12',
            path:kidPlaying12,
            category: 'holiday-club'
        },
        {
            title: 'kidPlayingOnIce',
            path:kidPlayingOnIce,
            category: 'all'
        },
        {
            title: 'kidAtTheFarm',
            path:kidAtTheFarm,
            category: 'holiday-club'
        },
        {
            title: 'kidAtTheZoo',
            path:kidAtTheZoo,
            category: 'holiday-club'
        },
        {
            title: 'kidsBeingCreative',
            path:kidsBeingCreative,
            category: 'holiday-club'
        },
        {
            title: 'holiday lesson computer',
            path: holidayLessonComputer,
            category: 'holiday-club'
        },
        {
            title: 'kidsClimbing',
            path:kidsClimbing,
            category: 'holiday-club'
        },
        {
            title: 'kidsExploring',
            path:kidsExploring,
            category: 'holiday-club'
        },
        {
            title: 'kidsInAPlay',
            path:kidsInAPlay,
            category: 'holiday-club'
        },
        {
            title: 'kidsLearning',
            path:kidsLearning,
            category: 'holiday-club'
        },
        {
            title: 'kidsLearning2',
            path:kidsLearning2,
            category: 'holiday-club'
        },
        {
            title: 'kidsPlayingWithToySeaAnimals',
            path:kidsPlayingWithToySeaAnimals,
            category: 'holiday-club'
        },
        {
            title: 'kidsLearning3',
            path:kidsLearning3,
            category: 'holiday-club'
        },
        {
            title: 'kidsWahingFruits',
            path:kidsWahingFruits,
            category: 'holiday-club'
        },
        {
            title: 'kidWithTheOnion',
            path:kidWithTheOnion,
            category: 'holiday-club'
        },
        {
            title: 'kidsWithAToy',
            path:kidsWithAToy,
            category: 'holiday-club'
        },
        {
            title: 'plantNursery',
            path:plantNursery,
            category: 'holiday-club'
        },
        {
            title: 'teacherGuidingKidHowToStartAFire',
            path:teacherGuidingKidHowToStartAFire,
            category: 'holiday-club'
        },
        {
            title: 'theMeasuringKid',
            path:theMeasuringKid,
            category: 'holiday-club'
        },
        {
            title: 'theMeasuringKidBeingCretive',
            path:theMeasuringKidBeingCretive,
            category: 'holiday-club'
        },
        // {
        //     title: 'after-school-club',
        //     path:AfterSchoolClub,
        //     category: 'after-school-club'
        // },
        {
            title: 'kid-playing-with-a-doll',
            path:KidPlayingWithADoll,
            category: 'after-school-club'
        },
        {
            title: 'kid-playing-with-a-doll',
            path:kidsPlayingWithADoll,
            category: 'after-school-club'
        },
        {
            title: 'brees-little-stars-after-school-club',
            path:breesLittlekidsAfterSchoolClub,
            category: 'after-school-club'
        },
        {
            title: 'boy-thumbing-up',
            path:boyThumbingUp,
            category: 'after-school-club'
        },
        {
            title: 'brees-little-stars-after-school-club',
            path:breesLittlekidsAfterSchoolClub2,
            category: 'after-school-club'
        },
        {
            title: 'boyWithAPoliceHat',
            path:boyWithAPoliceHat,
            category: 'after-school-club'
        },
        {
            title: 'breesLittleKidsClassroom',
            path:breesLittleKidsClassroom,
            category: 'after-school-club'
        },
        {
            title: 'kidsBaking',
            path:kidsBaking,
            category: 'after-school-club'
        },
        {
            title: 'kidLearningAfterSchoolClub',
            path:kidLearningAfterSchoolClub,
            category: 'after-school-club'
        },
        {
            title: 'kidLearningAfterSchoolClub2',
            path:kidLearningAfterSchoolClub2,
            category: 'after-school-club'
        },
        {
            title: 'chefKid',
            path:chefKid,
            category: 'after-school-club'
        },
        {
            title: 'kidPlayinAfterSchoolClub',
            path:kidPlayinAfterSchoolClub,
            category: 'after-school-club'
        },
        {
            title: 'kidReadingAfterSchoolClub',
            path:kidReadingAfterSchoolClub,
            category: 'after-school-club'
        },
        // {
        //     title: 'kidLearningAfterSchoolClub3',
        //     path:kidLearningAfterSchoolClub3,
        //     category: 'after-school-club'
        // },
        {
            title: 'chefGirl',
            path:chefGirl,
            category: 'after-school-club'
        },
        {
            title: 'chefKid2',
            path:chefKid2,
            category: 'after-school-club'
        },
        {
        title: 'teaCupSetting',
        path:teaCup,
        category: 'all',
    },
    {
        title: 'breeLittleStarsChairs',
        path:breeLittleStarsChairs,
        category: 'all',
    },
    {
        title: 'breeLittleStarsClassroom',
        path:breeLittleStarsClassroom,
        category: 'all',
    },
    {
        title: 'breeLittleStarsClassroom1',
        path:breeLittleStarsClassroom1,
        category: 'all',
    },
    {
        title: 'breeLittleStarsChristmasTree',
        path:breeLittleStarsChristmasTree,
        category: 'all',
    },
    {
        title: 'paintedUmbrella',
        path:paintedUmbrella,
        category: 'all',
    },
    {
        title: 'breeLittleStars',
        path:breeLittleStars,
        category: 'all',
    }
    ]
}

