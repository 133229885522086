import Logo from "../../assets/img/logo.png"
import Hamburger from 'hamburger-react'
import '../../assets/css/header.css'
import { NavLink, useLocation } from "react-router-dom"




const Header = (props) => {
    const location = useLocation();

    const { pathname } = location;

    const activeLink = pathname.split("/");

    //     window.addEventListener('scroll', navOnwall);
    //     function navOnwall() {
    //         const header = document.querySelector('.Header');
    //         const val = 91;
    //         const scrollValue = window.scrollY;

    //         if (window.innerWidth <= 1010) {
    //             if(scrollValue >= val ) {
    //             header.style.position = 'fixed';
    //             header.style.top = 0;
    //             header.style.left = 0;
    //             header.style.right = 0;
    //         } else header.style.display = 'none !important';
    //     }

    // }



    return (
        <header className="Header">

            <img className="logo" onClick={()=>{
                window.location.href = '/';
            }} src={Logo} alt="logo" />


            <nav className="Header-nav">
                <span className="nav-opt">
                    <NavLink to="/">HOME</NavLink>
                    <span className="nav-opt-active Home" id="Home">{activeLink[1] === "" ? (<i className="fas fa-ellipsis-h"></i>) : ""}</span>
                </span>
                <span className="nav-opt">
                    <NavLink to="/about">ABOUT US</NavLink>
                    <span className="nav-opt-active About-us" id="About">{activeLink[1] === "about" ? (<i className="fas fa-ellipsis-h"></i>) : ""}</span>
                </span>
                <span className="nav-opt">
                    <NavLink to="/services">SERVICES</NavLink>
                    <span className="nav-opt-active Services">{activeLink[1] === "services" ? (<i className="fas fa-ellipsis-h"></i>) : ""}</span>
                </span>
                <span className="nav-opt">
                    <NavLink to="/calendar">CALENDAR</NavLink>
                    <span className="nav-opt-active Calendar">{activeLink[1] === "calendar" ? (<i className="fas fa-ellipsis-h"></i>) : ""}</span>
                </span>
                <span className="nav-opt">
                    <NavLink to="/gallery">GALLERY</NavLink>
                    <span className="nav-opt-active Gallery">{activeLink[1] === "gallery" ? (<i className="fas fa-ellipsis-h"></i>) : ""}</span>
                </span>
                <span className="nav-opt">
                    <NavLink to="/contact">CONTACT</NavLink>

                    <span className="nav-opt-active Contact">{activeLink[1] === "contact" ? (<i className="fas fa-ellipsis-h"></i>) : ""}</span>
                </span>
                <span className="nav-opt">
                    <NavLink to="/career">Careers</NavLink>
                    <span className="nav-opt-active Career">{activeLink[1] === "career" ? (<i className="fas fa-ellipsis-h"></i>) : ""}</span>
                </span>
                <NavLink
                        to="/register"
                    className="header-button"
                > Register Your Child </NavLink>
            </nav>

            <Hamburger onToggle={toggled => {

                if (toggled) {
                    props.setHome('block')

                }
                else {
                    props.setHome('none')
                }
            }} />
        </header>
    )
}



export default Header