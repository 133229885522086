import { useState, useLayoutEffect } from "react"
import { Link } from "react-router-dom"
import "../assets/css/services.css"
import Header from "./attactments/header"
import MinHeader from "./attactments/min-header";
import right from "../assets/img/right.png"
import left from "../assets/img/left.png"
import meals2 from "../assets/img/meals2.png"
import feesAndFunding from "../assets/img/fees-and-funding.png"
import Footer from "./attactments/footer"
import holidayClub from "../assets/img/holiday-club-3456867432356554.png"
import RegisterationModal from "./attactments/registerModal";
import ScrollLink from "./attactments/scrollLink";
import mealImg from "../assets/img/5B8DAA2B-5474-4934-A70B-9363A7C47954.png"



const Services = () => {
    const [servicesStore, setServicesStore] = useState('none');

    useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

    return (
        <>
            <Header setHome={setServicesStore} />
            <MinHeader Home={servicesStore} />
            <section className="services-sect-1">
                <h1>Nursery and Pre-School Services</h1>

                <div className="serv-sect-card-container">
                    <div className="serv-sect-carton">
                        <div className="serv-sect-1-card">
                            <div className="serve-card-img card-img-1"></div>
                            <h4>Baby Stars <br/> Age: 3 to 12 months</h4>
                            <p>
                                We understand that not all families will be in the same situations, and some parents/ carers will need our services earlier than others, which is why we have a dedicated baby team, who are on hand to nurture, care and help your baby develop in their early stages of life. We have a comfortable baby area, in which they will be provided with…
                                </p>
                            <p><Link to="/services/babystars">More Info</Link></p>
                        </div>


                        <div className="serv-sect-1-card">
                            <div className="serve-card-img card-img-2"></div>
                            <h4>Little Stars <br/> Age: 1 to 2 years</h4>
                            <p>
                                As baby stars become increasingly mobile, and have gained physical independence and control, they will be transitioned gradually to little stars, helping them to become independent in daily activities. They will also have the opportunity to transition to rising stars as they approach 36 months. Our little stars enjoy an enriched timetable…
                                </p>
                            <p><Link to="/services/littlestars">More Info</Link></p>
                        </div>

                        <div className="serv-sect-1-card">
                            <div className="serve-card-img card-img-3"></div>
                            <h4>Rising Stars <br/> Age: 3 to 5 years</h4>
                            <p>We believe our rising stars are well equipped with the right resources and experiences that they need to start school. They are exposed to the world through our advanced curriculum using our ‘core books’ which have been carefully selected to promote the development of communication and language as well as inspiring a love for books and reading. Children have the opportunity to...</p>
                            <p><Link to="/services/risingstars">More Info</Link></p>
                        </div>

                    </div>
                </div>

                <Link
                         to="/register" 
                    className="global-button"
                >Register Your Child</Link>
            </section>

            <section className="services-sect-2">
                <div className="serv-sect-2-container-1">
                    <div>
                        <h1>After-School Club</h1>
                        <p><span className="serv-sect-2-span">Shooting stars</span> – Age 4 to 11 Years</p>
                        <p>Our shooting stars are not left out of the great experiences available at Bree’s Little Stars!</p>
                        <p>We offer:</p>
                        <ul className="fa-ul">
                            <li><span className="fa-li"><i className="fas fa-star star-one"></i></span>
                                After school club – for Morden Mount primary school and
                                James Wolfe School
                            </li>

                        </ul>
                        <p>To use our after school club services, please use the link below to register your child.</p>
                        <div className="sect-4-div-to-link">
                                <Link
                                    to="/register"
                                    className="header-button"
                                >Register your child</Link>
                            </div>
                    </div>
                    <div className="serv-sect-2-img-container">
                        <img src={right} alt="right" />
                    </div>
                </div>

                <div className="serv-sect-2-container-1 sect-2-div">
                    <div className="serv-sect-2-img-1">
                        <img src={left} alt="left" />
                    </div>
                    <div className="serv-sect-2-text">
                        <p>There is never a dull moment and your child will never be bored as there is a wealth of activities to get involved in. Children are able to play to their heart's content and develop social skills within a stimulating atmosphere. We believe young children who have working parents should not be at a disadvantage. We pride ourselves on making them feel loved in a home away from home setting.</p>
                    </div>
                </div>
            </section>



            <section className="services-sect-4">
                <div className="serv-sect-4-header">
                    <h1>Holiday Club</h1>
                    <p>Age 4 to 11 Years</p>

                    <div className="serv-sect-2-container-1">
                        <div className="serv-sect-2-text-container">
                            <p>
                                 Our holiday club services run during each UK school holiday – except the Christmas holiday. We offer daycare for children aged 4-11 at Morden Mount Primary School. Our holiday club is always jam packed with exciting activities such as canvas painting, Lego, arts & crafts, and a chance for children to learn some great and useful skills such as sewing, baking, survival skills, and coding, just to name a few. The weekly timetables get us all excited, and so do the trips! Look out for specific details and a sign-up form closer to each holiday break (except Christmas).
                            </p>

                            <p>
                                To register your interest in our holiday club, use the button below.
                            </p>

                        
                         <div className="sect-4-div-to-link">
                                <Link
                                    className="global-button"
                                    to="/register"
                                >Book now</Link>
                            </div>
                        
                        </div>
                        <div className="serv-sect-2-img-container services-sect-4-img-case">
                            <img src={holidayClub} alt="holiday_Computer_Lesson" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="serv-sect-5">
            <div className="serv-sect-2-container-1 sect-2-div">
                    <div className="serv-sect-5-img"> 
                            <img src={feesAndFunding} alt="feesAndFunding" />
                    </div>
                       
                    <div>
                        <h1>Fees and Funding</h1>
                        <p>All fees are payable monthly in advance on the first day of each calendar month</p>

                        <p>
                            All prospective parents/carers will be required to pay a deposit and the monthly fee in advance of your child taking up a place at Bree’s Little Stars.
                        </p>

                        <p>
                            We offer up to 30 Hours of government funded childcare. Get in touch with us for more details or please see the <a href="https://www.gov.uk/30-hours-free-childcare" target="blank">GOV.UK</a> website for further information.
                        </p>
                    </div>
                </div>
            </section>
            
           

            <section className="serv-sect-6">
            <div className="serv-sect-2-container-1">
                    <div>
                        <h1>Meals</h1>
                        <p>We at Bree’s Little Stars understand that during early childhood, it is important to establish healthy eating habits. We have designed an extensive 4-weekly seasonal menu, which will encourage children to eat a wide variety of foods and our children really enjoy mealtimes and love the family feel of eating together. Whether your child is in our care for the full day or just a few hours, Bree’s Little Stars is dedicated to offering your child freshly prepared wholesome meals, specific to their dietary requirements, as well as offering milk and fresh fruits daily. Click <a href={mealImg} target="_blank" rel="noopener noreferrer">here</a>  to see our sample menu.</p>
                    </div>
                    
                    <div className="serv-sect-2-img-container">
                        <img src={meals2} alt="meals" />
                    </div>
                </div>
            </section>

            <section className="services-sect-5">
                <h1>Important Dates</h1>
                <Link to="/calendar" className="serv-sect-5-button">Find Out</Link>
            </section>
            <RegisterationModal />
            <ScrollLink/>
            <Footer />
        </>
    );
}


export default Services;