import { useState, useLayoutEffect } from "react";
import "../assets/css/registeration.css";
import Header from "./attactments/header";
import MinHeader from "./attactments/min-header";
import Footer from "./attactments/footer";
import ScrollLink from "./attactments/scrollLink";
// import img1 from "../assets/img/slide/4.jpg";
import img2 from "../assets/img/slide/5.jpg";

const Registeration = () => {
  const [contactStore, setContactStore] = useState("none");

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <Header setHome={setContactStore} />
      <MinHeader Home={contactStore} />

      <section className="reg_showcase">
        {/* <div
          className="reg_1"
          onMouseEnter={(e) => {
            if (window.innerWidth > 494) {
              document.querySelector(
                `.reg_1`
              ).style.backgroundImage = `url(${img1})`;
            }
          }}
          onMouseLeave={(e) => {
            if (window.innerWidth > 494) {
              document.querySelector(`.reg_1`).style.backgroundImage = `none`;
              document.querySelector(`.reg_1`).style.transition = `0.7s`;
            }
          }}
        >
          <div>
            <h1>Register your child at Morden Mount</h1>

            <img className="regImg" src={img1} alt="Morden Mount" />
            <div className="btn-container">
              <a
                href="https://cheqdin.com/app/portal/public/registrations/Bree%27s%20Little%20Stars/MTEwNg=="
                target="_blank"
                rel="noopener noreferrer"
                className="global-button"
                style={{ marginRight: `1rem` }}
              >
                {" "}
                Register Your Child{" "}
              </a>

              <a
                href="https://cheqdin.com/app/portal/public/online-booking/Bree%27s%20Little%20Stars/MTEwNg=="
                target="_blank"
                rel="noopener noreferrer"
              >
                Book now
              </a>
            </div>
          </div>
        </div> */}
        <div
          className="reg_2"
          onMouseEnter={(e) => {
            if (window.innerWidth > 494) {
              document.querySelector(
                `.reg_2`
              ).style.backgroundImage = `url(${img2})`;
            }
          }}
          
          onMouseLeave={(e) => {
            if (window.innerWidth > 494) {
              document.querySelector(`.reg_2`).style.backgroundImage = `none`;
              document.querySelector(`.reg_2`).style.transition = `0.7s`;
            }
          }}
        >
          <div>
            <h1>Register your child at James Wolfe</h1>
            <img className="regImg" src={img2} alt="Morden Mount" />
            <div className="btn-container">
              <a
                href="https://cheqdin.com/app/portal/public/registrations/bree-s-little-stars-james-wolfe/MjU0OQ%3D%3D?fid=MTU2OA%3D%3D&time=1670510221"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: `1rem` }}
              >
                Register Your Child
              </a>

              <a
                className="global-button"
                href="https://cheqdin.com/app/portal/public/online-booking/Bree%E2%80%99s%20Little%20Stars%20James%20Wolfe/MjU0OQ=="
                target="_blank"
                rel="noopener noreferrer"
              >
                Book now
              </a>
            </div>
          </div>
        </div>
      </section>

      <ScrollLink />
      <Footer />
    </>
  );
};

export default Registeration;
