import { useState, useLayoutEffect } from "react";
import "../assets/css/register.css";
import "../assets/css/registeration.css";
import "../assets/css/holiday.css";
import Header from "./attactments/header";
import MinHeader from "./attactments/min-header";
import Footer from "./attactments/footer";
import dotParttern from "../assets/img/modal_pattern/dot_pattern.svg";

const NotFoundComponent = () => {
  const [notFound, setNotFound] = useState("none");
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  return (
    <main>
      <Header setHome={setNotFound} />
      <MinHeader Home={notFound} />
      <section style={{display: "flex", flexDirection:"column", alignItems:"center", background: "#fefdf9", justifyContent: "center", textAlign: "center"}}>
        <div>
          <h1 style={{fontWeight: 900, fontSize: "8vw", color: "#0d354e"}}>OOPS</h1>
          <h2>404 Not not found.</h2>
        </div>

        <p className="h_info">
           Return back to the prvious page or click the home menu link to go to the home page.
        </p>
        <img className="dotParttern" src={dotParttern} alt="dotParttern" />
        <img className="dotParttern1" src={dotParttern} alt="dotParttern" />
      </section>

     
      <Footer />
    </main>
  );
};

export default NotFoundComponent;
