import { useState, useLayoutEffect } from "react"
import "../assets/css/services-extended.css"
import Header from "./attactments/header";
import MinHeader from "./attactments/min-header";
import Footer from "./attactments/footer";
import CarouselContainer from "./attactments/quote-carousel";
import risingStars from "../assets/img/girl-climbing.png"
import RegisterationModal from "./attactments/registerModal";
import ScrollLink from "./attactments/scrollLink";



const ServicesExtended3 = () => {

    const [Home, setHome] = useState('none');

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className="services-3">
            <Header setHome={setHome} />
            <MinHeader Home={Home} />
            <section className="serv-extend-container">
                 <div className="showcase-text">
                    <h2>Rising Stars</h2>
                    <h1>Age: 3 to 5 years</h1>
                </div>
            </section>

            <section className="serv-extend-sect-2">
                <div className="serv-sect-2-container-1">
                    <div className="services-extend-text-container">
                        <p>
                            We believe our rising stars are well equipped with the right resources and experiences that they need to start school. They are exposed to the world through our advanced curriculum using our ‘core books’ which have been carefully selected to promote the development of communication and language, as well as inspiring a love for books and reading. Children have the opportunity to learn through play, including a balance of child-led and adult-led activities. We cater to the individual needs of our rising stars to ensure they never miss out on the opportunity to be the best and do their best.
                        </p>

                    </div>
                    <div className="serv-sect-2-img-container services-extend-sect-1-img-case">
                        <img src={risingStars} alt="risingStars" />
                    </div>
                </div>
            </section>
                <CarouselContainer/>
                <RegisterationModal/>
                <ScrollLink/>
            <Footer/>
        </div>
    );
}

export default ServicesExtended3;