import {useLayoutEffect } from "react"
import './assets/css/App.css';
import {  Switch, Route } from "react-router-dom"
import Home from "./components/home"
import About from "./components/about"
import Services from "./components/services"
import Calendar from "./components/calendar"
import Gallery from "./components/gallery"
import Contact from "./components/contact"
import Career from "./components/career"
import ServicesExtended1 from "./components/services-extended-1"
import ServicesExtended2 from "./components/services-extended-2"
import ServicesExtended3 from "./components/services-extended-3"
import Registeration from "./components/registeration.js"
import GalleryExtended from "./components/gallery-extended"
import NotFoundComponent from "./components/not_found.js";
// import RegisterContainer from "./components/register.js";
// import HolidayDetailsPage from "./components/holiday.js";

const App = () => {
  
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  return (
    <main className="App">
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/about' component={About}/>
              <Route exact path='/services' component={Services}/>
              <Route exact path='/calendar' component={Calendar}/>
              <Route exact path='/gallery' component={Gallery} />
              <Route path='/view' component={GalleryExtended}/>
              <Route exact path='/contact' component={Contact}/>
              <Route exact path='/career' component={Career}/>
              <Route exact path='/services/littlestars' component={ServicesExtended1}/>
              <Route exact path='/services/babystars' component={ServicesExtended2}/>
              <Route exact path='/services/risingstars' component={ServicesExtended3}/>
              <Route exact path='/register' component={Registeration}/>
              {/* <Route exact path='/reg' component={RegisterContainer}/>
              <Route exact path='/summer_holiday_club' component={HolidayDetailsPage}/> */}
              <Route path="*" component={()=>(<NotFoundComponent/>)} />
            </Switch>
    </main>
  );
}

export default App;
