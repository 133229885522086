import "../../assets/css/modal.css";
import image from "../../assets/img/summer_holiday_kids.svg";
import geoPattern from "../../assets/img/modal_pattern/geo_pattern_1.svg"
import circleParttern from "../../assets/img/modal_pattern/circle_Parttern.svg"
import circleParttern1 from "../../assets/img/modal_pattern/circle_Parttern_1.svg"
import dotParttern from "../../assets/img/modal_pattern/dot_pattern.svg"
import { Link } from "react-router-dom";

const CustomModal = ({ setShow }) => {
  return (
    <section className="modal_container fadeIn">
      <div className="c_modal">
        <div>
          <h2>Holiday Food and Fun! Holiday Club</h2>
          <p>Know more about our holiday summer club.</p>
          <Link className="link" to="/calendar">
            View more
          </Link>
        </div>
        <div>
          <div onClick={()=>setShow(false)} className="c_close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={4.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                width={40}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>
          <img src={image} alt="showcase_image" />
        </div>
        <img className="geo_pattern" src={geoPattern} alt="pattern_1" />
        <img className="circle_Parttern_1" src={circleParttern1} alt="circle_Parttern_1" />
        <img className="circleParttern" src={circleParttern} alt="circleParttern" />
        <img className="dotParttern" src={dotParttern} alt="dotParttern" />
      </div>
    </section>
  );
};

export default CustomModal;
